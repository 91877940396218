import { useEffect } from "react";
import React from 'react';
import { Navigate } from "react-router-dom";

import { connect } from "react-redux";
import { LoadingComponent } from "./LoadingComponent";
import { ParamsComponent } from "./ParamsComponent";
// import PropTypes from "prop-types";

export function PrivateComponentInternal ({ component: Component, auth, ...rest }) {
    const isAuthenticated = auth && auth.isAuthenticated;
    /* */
    /* Set the page title for authenticated users */
    /* */
    /* The default page title is set in index.html */
    useEffect(() => {
        if (isAuthenticated) {
            document.title = 'Jurisolutions - Gestion des dossiers';
        }
    }, [isAuthenticated]);

    // console.log('PrivateRoute isAuthenticated:', auth);

    if (auth.isLoading) {
        return <LoadingComponent/>
    } else if (!auth.isAuthenticated) {
        return <Navigate to="identification" replace />
    } else {
        return <ParamsComponent component={Component}/>
    }
}

const mapStateToProps = state => ({ auth: state.auth });

export const PrivateComponent = connect(mapStateToProps)(PrivateComponentInternal);
