var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import HttpsIcon from "@material-ui/icons/Https";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import EditIcon from '@material-ui/icons/Edit';
import { connect, useDispatch } from "react-redux";
import { addCase, createCaseVariant, getCaseVariants, patchCase, patchCaseVariant } from "../../../../actions/cases";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
function formatDate(dateString) {
    if (!dateString) {
        return '';
    }
    var options = {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };
    var date = new Date(dateString);
    // Formatting the date in French locale
    return date.toLocaleDateString('fr-FR', options).replace('.', ''); // Removing period after month abbreviations
}
function VersionListItem(props) {
    var propName = props.name, propDescription = props.description;
    // Local state for edit mode and input values
    var _a = useState(false), isEditing = _a[0], setIsEditing = _a[1];
    var _b = useState(props.name || ""), name = _b[0], setName = _b[1];
    var _c = useState(props.description || ""), description = _c[0], setDescription = _c[1];
    useEffect(function () {
        if (propName) {
            setName(propName);
        }
    }, [propName]);
    useEffect(function () {
        if (propDescription) {
            setDescription(propDescription);
        }
    }, [propDescription]);
    // Handlers for toggling edit mode and saving changes
    var handleNameChange = function (event) {
        setName(event.target.value);
    };
    var handleDescriptionChange = function (event) {
        setDescription(event.target.value);
    };
    var onChangeDone = function (e) {
        var _a, _b;
        if (!isEditing) {
            return;
        }
        setIsEditing(false);
        if (name != props.name) {
            (_a = props.changeName) === null || _a === void 0 ? void 0 : _a.call(props, name);
        }
        if (description != props.description) {
            (_b = props.changeDescription) === null || _b === void 0 ? void 0 : _b.call(props, description);
        }
    };
    var handleEdit = function (e) {
        e.stopPropagation();
        setIsEditing(true); // Double click action
    };
    return React.createElement("div", { style: { background: props.isActive ? "rgba(43, 153, 244, 0.2)" : "", borderRadius: 4, "padding": "4px 0px 4px 20px", cursor: isEditing ? 'default' : 'pointer' }, onClick: function () { return !isEditing ? props.onSelect() : null; } },
        React.createElement("div", { style: { display: 'flex' } },
            isEditing ? (React.createElement("input", { type: "text", value: name, autoFocus: true, onChange: handleNameChange, style: { fontSize: '16px', fontWeight: 'bold' } })) : (React.createElement("div", null,
                React.createElement("span", { style: { fontSize: "16px", color: "#000000", fontWeight: 'bold' } }, name),
                props.isLocked ?
                    React.createElement(Tooltip, { title: "Version verrouill\u00E9e", placement: "top-start", PopperProps: { style: { zIndex: 200000 } } },
                        React.createElement(HttpsIcon, { fontSize: "small", color: 'inherit', style: { marginBottom: "5px" } }))
                    :
                        React.createElement(IconButton, { onClick: handleEdit, "aria-label": "Modifier le titre et la description", size: "small" },
                            React.createElement(Tooltip, { title: "Modifier le titre et la description", placement: "top-end", PopperProps: { style: { zIndex: 200000 } } },
                                React.createElement(EditIcon, { style: { fontSize: "14px", marginBottom: "5px" } }))))),
            React.createElement("div", { style: { display: 'flex' }, className: 'ml-auto', onClick: function (e) { return e.stopPropagation(); } },
                React.createElement(IconButton, { onClick: function () { return props.onAdd(); }, "aria-label": "Nouvelle version", size: "small" },
                    React.createElement(Tooltip, { title: "Cr\u00E9er une nouvelle version \u00E0 partir de celle-ci", placement: "top-end", PopperProps: { style: { zIndex: 200000 } } },
                        React.createElement(FileCopyIcon, { fontSize: "small" }))),
                props.canDelete && !props.isLocked ?
                    React.createElement(IconButton, { onClick: function () { return props.onDelete(); }, "aria-label": "Delete", size: "small" },
                        React.createElement(Tooltip, { title: "Supprimer la version", placement: "top-start", PopperProps: { style: { zIndex: 200000 } } },
                            React.createElement(DeleteIcon, { fontSize: "small" })))
                    : null)),
        React.createElement("div", { style: {
                color: description ? "dimgrey" : "lightgrey",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
            } }, isEditing ? (React.createElement(React.Fragment, null,
            React.createElement("textarea", { value: description, onChange: handleDescriptionChange, style: { fontSize: '12px', color: 'dimgrey', width: '100%', minHeight: '50px', maxHeight: '200px' } }),
            React.createElement("button", { onClick: onChangeDone, className: 'btn btn-sm secondary' }, "Enregistrer les modifications"))) : (React.createElement("span", null, description))),
        React.createElement("div", { className: 'case-metadata' },
            React.createElement("small", null,
                "Version cr\u00E9\u00E9e le ",
                formatDate(props.creationDate),
                " - Modifi\u00E9e le ",
                formatDate(props.modificationDate))));
}
function CaseVersionDeleteDialog(props) {
    return React.createElement(Dialog, { open: props.open, onClose: props.onClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
        React.createElement(DialogTitle, { id: "alert-dialog-title" },
            "Supprimer la version ",
            props.variantTitle,
            " ?"),
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, { id: "alert-dialog-description" },
                "Souhaitez-vous vraiment supprimer la version ",
                props.variantTitle,
                " du dossier ",
                props.caseTitle,
                " ? Cette suppression sera d\u00E9finitive.")),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: props.onClose, color: "primary", size: "small", title: "Supprimer le dossier" }, "Annuler"),
            React.createElement(Button, { name: "confirm-delete-button", onClick: props.onDelete, variant: "contained", color: "secondary", size: "small", startIcon: React.createElement(DeleteIcon, null), autoFocus: true }, "Confirmer la suppression")));
}
function CaseVersionDuplicateDialog(props) {
    var open = props.open, variant = props.variant;
    var _a = useState(""), title = _a[0], setTitle = _a[1];
    useEffect(function () {
        if (open) {
            setTitle(variant.title + " - (copie)");
        }
    }, [open, variant]);
    return React.createElement(Dialog, { open: props.open, onClose: props.onClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
        React.createElement(DialogTitle, { id: "alert-dialog-title" },
            "Cr\u00E9er une nouvelle version du dossier ",
            props.caseItem.title),
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, { id: "alert-dialog-description" }, "Veuillez pr\u00E9ciser le titre de la nouvelle version :"),
            React.createElement(TextField, { autoFocus: true, value: title, onChange: function (e) { return setTitle(e.target.value); } })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: props.onClose, color: "primary", size: "small" }, "Annuler"),
            React.createElement(Button, { onClick: function () { return props.onSubmit(title); }, variant: "contained", color: "primary", size: "small", autoFocus: true, disabled: !title || title.length === 0, startIcon: React.createElement(NoteAddIcon, null) }, "Cr\u00E9er la version")));
}
function CaseVersionSwitchWithUnsavedChangesDialog(props) {
    var open = props.open, variant = props.variant;
    return React.createElement(Dialog, { open: props.open, onClose: props.onClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
        React.createElement(DialogTitle, { id: "alert-dialog-title" }, "Vos derni\u00E8res modifications n'ont pas \u00E9t\u00E9 enregistr\u00E9es"),
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, { id: "alert-dialog-description" }, "Vos derni\u00E8res modifications sur cette version n'ont pas \u00E9t\u00E9 enregistr\u00E9es. \u00CAtes-vous s\u00FBr(e) de vouloir changer de version sans enregistrer vos modifications ?")),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: props.onClose, color: "primary", size: "small" }, "Annuler"),
            React.createElement(Button, { onClick: function () { return props.onSubmit(); }, variant: "contained", color: "primary", size: "small", autoFocus: true }, "Changer de version sans enregistrer")));
}
var SwitchToVersionAction;
(function (SwitchToVersionAction) {
    SwitchToVersionAction["SELECT"] = "select";
    SwitchToVersionAction["COPY"] = "copy";
})(SwitchToVersionAction || (SwitchToVersionAction = {}));
function CaseVersionDialogInternal(props) {
    var _this = this;
    var dispatch = useDispatch();
    var _a = useState(null), deleteVersionId = _a[0], setDeleteVersionId = _a[1];
    var _b = useState(null), copyVersionId = _b[0], setCopyVersionId = _b[1];
    var _c = useState(null), switchToVersionId = _c[0], setSwitchToVersionId = _c[1];
    var _d = useState(null), switchToVersionAction = _d[0], setSwitchToVersionAction = _d[1];
    if (!props.case) {
        return React.createElement(React.Fragment, null);
    }
    var allCaseVariants = __spreadArray([], (props.caseVariants || []), true);
    var variantsSorted = allCaseVariants.filter(function (value) { return !value.is_deleted; }).sort(function (a, b) {
        if (a.date_created < b.date_created) {
            return -1;
        }
        if (a.date_created > b.date_created) {
            return 1;
        }
        return 0;
    });
    var deleteVersionCase = variantsSorted.find(function (v) { return v.id === deleteVersionId; });
    var activeVersionId = props.case.variant;
    var activeVersion = allCaseVariants.find(function (v) { return v.id === activeVersionId; });
    function isVariantActive(variant) {
        return props.case.variant === variant.id;
    }
    if (!props.open) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement("div", { style: {
            border: "1px solid grey",
            borderRight: "none",
            position: 'fixed',
            background: 'white',
            width: '380px',
            borderRadius: '20px 0 0 20px',
            height: "calc(100% - 185px)",
            "right": "0",
            "top": "105px",
            "zIndex": "100000",
            padding: '40px 20px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: 40,
        } },
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement("span", { style: {
                    "fontSize": "20px",
                    "color": "#000000",
                    fontWeight: 'bold',
                } }, "Versions"),
            React.createElement(IconButton, { onClick: function () { return props.onClose(); }, "aria-label": "close", size: "small" },
                React.createElement(CloseIcon, null))),
        React.createElement(CaseVersionSwitchWithUnsavedChangesDialog, { open: switchToVersionId != null, onClose: function () { return setSwitchToVersionId(null); }, variant: variantsSorted.find(function (v) { return v.id === switchToVersionId; }) || {}, caseItem: props.case, onSubmit: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!switchToVersionId) {
                                return [2 /*return*/];
                            }
                            if (!(switchToVersionAction === SwitchToVersionAction.COPY)) return [3 /*break*/, 1];
                            setCopyVersionId(switchToVersionId);
                            return [3 /*break*/, 3];
                        case 1:
                            if (!(switchToVersionAction === SwitchToVersionAction.SELECT)) return [3 /*break*/, 3];
                            return [4 /*yield*/, dispatch(patchCase(props.case.id.toString(), { variant: switchToVersionId }))];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3:
                            setSwitchToVersionId(null);
                            return [2 /*return*/];
                    }
                });
            }); } }),
        React.createElement(CaseVersionDuplicateDialog, { open: copyVersionId !== null, variant: variantsSorted.find(function (v) { return v.id === copyVersionId; }) || {}, caseItem: props.case, onClose: function () { return setCopyVersionId(null); }, onSubmit: function (title) { return __awaiter(_this, void 0, void 0, function () {
                var newVariant;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!copyVersionId) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, dispatch(createCaseVariant(props.case.id.toString(), copyVersionId, title))];
                        case 1:
                            newVariant = (_a.sent());
                            if (!newVariant || !newVariant.id) {
                                return [2 /*return*/];
                            }
                            // TODO block autosave
                            return [4 /*yield*/, dispatch(patchCase(props.case.id.toString(), { variant: newVariant.id }))];
                        case 2:
                            // TODO block autosave
                            _a.sent();
                            setCopyVersionId(null);
                            return [2 /*return*/];
                    }
                });
            }); } }),
        React.createElement(CaseVersionDeleteDialog, { open: deleteVersionId !== null, variantTitle: deleteVersionCase ? deleteVersionCase.title || '' : '', caseTitle: deleteVersionCase ? deleteVersionCase.title || '' : '', onClose: function () { return setDeleteVersionId(null); }, onDelete: function () { return __awaiter(_this, void 0, void 0, function () {
                var nextVersion;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, , 3, 4]);
                            if (!deleteVersionId || !deleteVersionCase) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, dispatch(patchCaseVariant(props.case.id.toString(), deleteVersionId, { is_deleted: true }))];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, dispatch(getCaseVariants(props.case.id.toString()))];
                        case 2:
                            _a.sent();
                            if (isVariantActive(deleteVersionCase)) {
                                nextVersion = allCaseVariants.find(function (v) { return !v.is_deleted && v.id !== deleteVersionId; });
                                if (nextVersion) {
                                    dispatch(patchCase(props.case.id.toString(), { variant: nextVersion.id }));
                                }
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            setDeleteVersionId(null);
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            }); } }),
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                rowGap: 4,
                overflowY: 'auto',
            } }, variantsSorted.sort().map(function (variant, index) {
            return React.createElement(VersionListItem, { canDelete: variantsSorted.length > 1, key: variant.id, onAdd: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (props.hasUnsavedChanges) {
                            setSwitchToVersionAction(SwitchToVersionAction.COPY);
                            setSwitchToVersionId(variant.id);
                            return [2 /*return*/];
                        }
                        setCopyVersionId(variant.id);
                        return [2 /*return*/];
                    });
                }); }, isLocked: variant.locked, name: variant.title, isActive: isVariantActive(variant), description: variant.description, creationDate: variant.date_created, modificationDate: variant.date_modified, changeName: function (value) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch(patchCaseVariant(props.case.id.toString(), variant.id.toString(), {
                                    id: variant.id,
                                    title: value,
                                }))];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, changeDescription: function (value) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch(patchCaseVariant(props.case.id.toString(), variant.id.toString(), {
                                    id: variant.id,
                                    description: value,
                                }))];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, onDelete: function () {
                    setDeleteVersionId(variant.id);
                }, onSelect: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!props.case) {
                                    return [2 /*return*/];
                                }
                                if (props.hasUnsavedChanges) {
                                    setSwitchToVersionAction(SwitchToVersionAction.SELECT);
                                    setSwitchToVersionId(variant.id);
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, dispatch(patchCase(props.case.id.toString(), { variant: variant.id }))];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } });
        })));
}
var mapStateToProps = function (state) {
    return { patchCase: patchCase };
};
export var CaseVersionDialog = connect(mapStateToProps, { addCase: addCase })(CaseVersionDialogInternal);
